<template lang="pug">
    footer#footer
        Redes
        //- Linguagem
</template>

<script>
export default {
    name: "component-footer",
    components: {
        Redes: () => import( /* webpackChunkName: "Redes" */ '@components/Redes/Redes'),
    },
}
</script>

<style lang="stylus" scoped src="./Footer.styl"></style>